@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--approach {
  padding-bottom: 0;
}

.approach {
  &__title {
    margin-bottom: 36px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 4.875rem;
    }

    @include media-breakpoint-down(md) {
      * {
        text-align: left !important;
      }
    }
  }

  &__images {
    max-width: 1440px;
    margin: 2.5rem auto 0;

    @include media-breakpoint-up(xxxl) {
      max-width: 1700px;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 48px;
    }
  }

  &__image {
    display: block;
    width: 100%;

    &--d:first-child:not(:only-child) {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--m {
      display: none;
      width: 100%;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    &--lottie {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: var(--body-width);
        height: 12.5rem;
        border-bottom: 1px solid $black;
        border-radius: 6.25rem;
        transform: translateX(-50%);

        @include media-breakpoint-down(xl) {
          height: 100px;
          border-radius: 50px;
        }

        @include theme-mode(dark) {
          border-color: #808080;
        }
      }

      svg * {
        transition: all 0.35s linear;
      }

      @include theme-mode(dark) {
        path[fill="rgb(0,0,0)"] {
          fill: $white;
        }

        path[stroke] {
          stroke: #808080;
        }
      }
    }
  }

  &__steps {
    @include media-breakpoint-up(lg) {
      margin-bottom: 8rem;
    }
  }

  &__step {
    width: 24.0625rem;
    max-width: 33.333%;
    flex: 0 1 24.0625rem;
    margin-bottom: 2.25rem;

    @include media-breakpoint-down(xl) {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      width: 480px;
      max-width: 100%;
      flex: 0 1 auto;
      margin-bottom: 36px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(3n+2) {
        position: relative;
        top: 4rem;
      }

      &:nth-child(3n+3) {
        position: relative;
        top: 8rem;
      }
    }

    &-image {
      max-width: 18.5rem;
      margin-bottom: 1.125rem;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      .of-cover__wrapper {
        padding-bottom: 70%;
      }
    }

    &-img {
      border: 1px solid $black;
      border-radius: $border-radius-2xl;
      overflow: hidden;
    }

    &-heading {
      margin-bottom: 1.5rem;
    }

    &-title {
      margin: 0;
      padding: 0.5625rem 1.125rem;
      border-radius: 2rem;
      background-color: $black;
      color: $white;

      @include theme-mode(dark) {
        background-color: $white;
        color: $black;
      }
    }

    &-icon {
      width: 2.25rem;
      height: 2.25rem;
      margin-left: 0.5rem;
    }

    [data-bs-theme='dark'] {
      .approach__step-icon {
        display: none;
      }
    }
  }
}

@include color-mode(dark) {
  .approach {
    &__step {
      [data-bs-theme='dark'] {
        .approach__step-icon {
          display: inline-block;
        }
      }

      [data-bs-theme='light'] {
        .approach__step-icon {
          display: none;
        }
      }
    }
  }
}
